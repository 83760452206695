.pl1 {
    display: block;
    width: 8em;
    height: 8em;
}

.pl1g,
.pl1rect {
    animation: pl1a 1.5s cubic-bezier(0.65, 0, 0.35, 1) infinite;
}

.pl1g {
    transform-origin: 64px 64px;
}

.pl1rect:first-child {
    animation-name: pl1b;
}

.pl1rect:nth-child(2) {
    animation-name: pl1c;
}

/* Animations */
@keyframes pl1a {
    from {
        transform: rotate(0);
    }

    80%,
    to {
        animation-timing-function: steps(1, start);
        transform: rotate(90deg);
    }
}

@keyframes pl1b {
    from {
        animation-timing-function: cubic-bezier(0.33, 0, 0.67, 0);
        width: 40px;
        height: 40px;
    }

    20% {
        animation-timing-function: steps(1, start);
        width: 40px;
        height: 0;
    }

    60% {
        animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        width: 0;
        height: 40px;
    }

    80%,
    to {
        width: 40px;
        height: 40px;
    }
}

@keyframes pl1c {
    from {
        animation-timing-function: cubic-bezier(0.33, 0, 0.67, 0);
        width: 40px;
        height: 40px;
        transform: translate(0, 48px);
    }

    20% {
        animation-timing-function: cubic-bezier(0.33, 1, 0.67, 1);
        width: 40px;
        height: 88px;
        transform: translate(0, 0);
    }

    40% {
        animation-timing-function: cubic-bezier(0.33, 0, 0.67, 0);
        width: 40px;
        height: 40px;
        transform: translate(0, 0);
    }

    60% {
        animation-timing-function: cubic-bezier(0.33, 1, 0.67, 1);
        width: 88px;
        height: 40px;
        transform: translate(0, 0);
    }

    80%,
    to {
        width: 40px;
        height: 40px;
        transform: translate(48px, 0);
    }
}