.nav-tab {
    position: relative;
    z-index: 1;
    white-space: nowrap;
    margin-right: 3px;
    margin-bottom: 3px;
}

.nav-tab button {
    position: relative;
    display: inline-block;
    padding: 1.5em 2em 1.35em;
    color: inherit;
    text-decoration: none;
    margin: 0 -3px;
    color: #4c4c4c;
    font-size: 0.9rem;
}

.nav-tab button::before,
.main-content {
    border: 1px solid #E5E7EB;

}

.nav-tab button::before {
    content: '';
    /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: .5em;
    left: 0;
    z-index: -1;
    border-bottom: none;
    border-radius: 15px 15px 0 0;
    background: #E5E7EB;
    transform: perspective(4.5px) rotateX(1deg);
    transform-origin: bottom;
    background: #f7f7f7;
    /* box-shadow: inset 0 -3px 3px rgba(0, 0, 0, 0.05); */
}

.nav-tab.left button {
    padding: 1.5em 2em 1.35em 1em;
}

.nav-tab.left button::before {
    transform-origin: bottom left;
}

.nav-tab.right button {
    padding: 1.5em 1.5em 1.25em 2.5em;
}

.nav-tab.right button::before {
    transform-origin: bottom right;
}

.nav-tab button.selected {
    z-index: 2;
    color: #1b1b1b;
}

.nav-tab button.selected::before {
    margin-bottom: -2px;
    box-shadow: none;
}

.nav-tab button.selected::before,
.main-content {
    background: #FFF;
}

.nav-tab button:not(.selected):hover {
    color: #000000;
}

.main-content {
    height: calc(100% - 55px);
    display: block;
    margin: -9px 0 30px -15px;
    padding: 1em;
    border-radius: 15px 0px 15px 15px;
    width: 100%;
    /* box-shadow: 0 3px 10px rgba(38, 59, 94, .1); */
}